import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusPipe } from "./status.pipe";

const PIPES = [
  StatusPipe,
];

@NgModule({
  declarations: [...PIPES],
  imports: [
    CommonModule,
  ],
  exports: [...PIPES],
})
export class PipesModule {}
