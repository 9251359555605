import { MatPaginatorIntl } from '@angular/material/paginator';

import { Subject } from 'rxjs';

export class PaginatorRus implements MatPaginatorIntl {
  changes: Subject<void> = new Subject<void>();

  itemsPerPageLabel: string = 'Кол-во на странице';
  nextPageLabel: string = 'Следующая страница';
  previousPageLabel: string = 'Предыдущая страница';
  firstPageLabel: string = 'Первая страница';
  lastPageLabel: string = 'Последняя страница';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return 'Страница 1 из 1';
    }

    const amountPages = Math.ceil(length / pageSize);

    return `Страница ${page + 1} из ${amountPages}`;
  }
}
