export class PageFilter {
  /**
   * Текущие фильтры
   */
  public filters: IPageFilter[] = [];

  /**
   * Добавить новый фильтр.
   * @param property Поле для фильтра
   * @param value Значение фильтра
   */
  public addFilter(property: string, value: string): void {
    const filterIndex = this.filters.findIndex(f => f.property == property);
    if (filterIndex == -1) {
      this.filters.push({
        property: property,
        value: value,
      });
    } else if (!this.filters[filterIndex].value.includes(value)) {
      this.filters[filterIndex].value = value;
    }
  }

  /**
   * Удалить фильтр по полю.
   * @param property Поле для фильтра
   */
  public removeFilter(property: string): void {
    const filterIndex = this.filters.findIndex(f => f.property == property);
    if (filterIndex !== -1) {
      this.filters.splice(filterIndex, 1);
    }
  }

}

export interface IPageFilter {
  property: string;
  value: string;
}
