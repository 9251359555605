import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs";
import { AuthService } from "@services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _router: Router, private _auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const update: any = {};
    update.headers = req.headers.set('Content-Type', 'application/json');
    update.headers = req.headers.set('Accept', 'application/json, application/problem+json');
    let token = localStorage.getItem('adminToken');
    if (this._auth.token) {
      let atoken = this._auth.token;
      update.headers = req.headers.set('Authorization', `Bearer ${atoken['token']}`);
    }
    const request = req.clone(update);

    return next.handle(request);
  }
}
