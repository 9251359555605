<ng-container [ngSwitch]="buttonType">

  <button *ngSwitchCase="'filter'"
          mat-icon-button
          [disabled]="disabled"
          [matTooltip]="tooltip || 'Фильтр'"
          type="button">
    <mat-icon>filter_list</mat-icon>
  </button>

  <button *ngSwitchCase="'add'"
          mat-raised-button
          type="button"
          [disabled]="disabled">
    <ng-content></ng-content>
  </button>

  <button *ngSwitchCase="'settings'"
          type="button"
          mat-raised-button
          [disabled]="disabled"
          [matTooltip]="tooltip">
    <ng-content></ng-content>
  </button>

  <button *ngSwitchDefault
          type="button"
          mat-raised-button
          [disabled]="disabled"
          [matTooltip]="tooltip">
    <ng-content></ng-content>
  </button>
</ng-container>
