export class CustomDialog {
  message: string;
  submitCallbackButtonText: string;
  cancelCallbackButtonText: string;
  submitCallback: any;
  cancelCallback: any

  constructor(message: string, submitCallbackButtonText: string, cancelCallbackButtonText: string, submitCallback?: any, cancelCallback?: any) {
    this.message = message;
    this.submitCallbackButtonText = submitCallbackButtonText;
    this.cancelCallbackButtonText = cancelCallbackButtonText;

    if (submitCallback) {
      this.submitCallback = submitCallback;
    }

    if (cancelCallback) {
      this.cancelCallback = cancelCallback;
    }
  }
}
