import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { filter } from 'rxjs/operators';
import { takeUntil } from "rxjs";
import { AppBaseDestroyComponent } from "@shared/components/base-component/base-component.component";

export interface Link {
  url: string;
  title: string;
  isHide?: boolean;
}


@Component({
  selector: 'mig-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class AppTabComponent extends AppBaseDestroyComponent implements OnInit {
  @Input() public links: Link[] = new Array<Link>();
  public activeLink!: string;


  constructor(
    private route: ActivatedRoute,
    private _router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initSubscriber();
  }

  private initSubscriber(): void {
    const route = this._router.url.split('?');

    this._router.events.pipe(
      takeUntil(this.ngUnsubscribe$),
      filter(routerEvent => routerEvent instanceof NavigationStart && !routerEvent.url.includes('?')),
    ).subscribe((routerEvent: any) => {
      this.activeLink = this.getTab(routerEvent.url);
    });

    this.activeLink = this.getTab(route[0]);
    this._router.navigate([this.activeLink], { relativeTo: this.route });
  }

  private getTab(url: string): string {
    let match = url.match(`(?!.*/).+`);
    if (match != null && match.length > 0) {
      let lastSegment = match[0].split('?')[0];

      return this.links.find(link => lastSegment == link.url) ? lastSegment : this.links[0].url;
    }

    return this.links[0].url;
  }
}
