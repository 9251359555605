import { Component, Input } from '@angular/core';
import { BaseFormFieldAbstractComponent } from "@models/base-form-field.abstract.component";

@Component({
  selector: 'mig-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends BaseFormFieldAbstractComponent {
  @Input() label: string = '';
  @Input() formClass: string = '';
  @Input() optionLabel: string = 'title';
  @Input() optionValue: string = 'value';
  @Input() options: any[] = [];
  @Input() multiple: boolean = false;
}
