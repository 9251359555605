import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "@services/auth.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.handleCheck(state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  handleCheck(url: string): boolean {

    if (this.authService.token) {
      this.authService.redirectUrl = url;
      return true;
    }
    this.authService.redirectUrl = url.split('?')[0].split('/');

    this.router.navigate(['/login']);
    return false;
  }
}
