import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDialog } from "@models/dialog.model";


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  public dialog: MatDialogRef<DialogComponent>;
  public dialogService: MatDialog;
  public customDialog: CustomDialog;

  constructor(dialog: MatDialogRef<DialogComponent>, dialogService: MatDialog, @Inject(MAT_DIALOG_DATA) customDialog: CustomDialog) {
    this.dialog = dialog;
    this.dialogService = dialogService;
    this.customDialog = customDialog;
  }

  public submitClick(): void {
    if (this.customDialog.submitCallback) {
      this.customDialog.submitCallback();
    }
    this.dialog.close();
  }

  public cancelClick(): void {
    if (this.customDialog.cancelCallback) {
      this.customDialog.cancelCallback();
    }
    this.dialog.close();
  }
}
