import { Component, Directive } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'formly-file-uploader',
  template: `
    <span style="margin-right: 10px">{{props.label}}</span>
    <input type="file"
           [formlyAttributes]="field"
           [hidden]="true"
           [formControl]="formControl">
    <input type="file"
           accept=".pdf"
           multiple
           [hidden]="true"
           (change)="props['fileUpload'](fileInput.files)"
           #fileInput/>
    <button mat-flat-button color="primary" (click)="fileInput.click()">Прикрепить документ</button>
    <div *ngFor="let item of formControl.value" style="display: flex; align-items: center">
      <span>{{item.name}}</span>
      <mat-icon (click)="props['deleteFile'](item)">delete</mat-icon>
    </div>
  `,
  styles: [``],
})

export class FormlyFileUploader extends FieldType<FieldTypeConfig> {
}

@Directive({
  selector: 'input[type=file]',
  host: {
    '(change)': 'onChange($event.target.files)',
    '(blur)': 'onTouched()',
  },
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: FileValueAccessor, multi: true }],
})
// https://github.com/angular/angular/issues/7341
export class FileValueAccessor implements ControlValueAccessor {
  value: any;
  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(_value: any) {}

  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
