import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Component, inject, OnInit} from "@angular/core";
import {tap} from "rxjs/operators";
import {Observable} from "rxjs";

import {FieldType} from "@ngx-formly/material";
import {FieldTypeConfig} from "@ngx-formly/core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'formly-select',
  styles: [
    `
      .container {
        border: 1px solid rgb(161, 160, 160);
        border-radius: 5px;
        padding: 15px;

        &:hover {
          border: 1px solid black;
        }

        .input-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          .input-item {
            width: 60%;
          }

          .button-item {
            margin-left: 40px;
          }
        }

        table {
          width: 80%;
        }

        .deleted-icon {
          &:hover {
            background-color: #adadad;
          }
        }

        mat-form-field {
          .mat-mdc-form-field-infix {
            padding: 16px 12px;
          }
        }

        .indentation-left {
          margin-left: 12px;
          margin-right: 12px;
        }
      }

    `
  ],
  template: `
    <form [formGroup]="additionalEmailForm">
      <div class="container">
        <div class="input-wrapper">
          <mat-form-field appearance="outline" class="input-item">
            <mat-label>{{ props.label }}</mat-label>
            <input [formControlName]="props['formFieldName']" matInput>
          </mat-form-field>

          <button mat-raised-button class="button-item" (click)="addAdditionalEmail()">Добавить почту</button>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <th style="width: 5%" mat-header-cell *matHeaderCellDef> id</th>
            <td mat-cell *matCellDef="let element"> {{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th style="width: 60%" mat-header-cell *matHeaderCellDef> E-mail</th>
            <td mat-cell *matCellDef="let element"> {{ element.title }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th style="width: 35%" mat-header-cell *matHeaderCellDef></th>

            <td mat-cell *matCellDef="let element">
              <button mat-icon-button matTooltip="Удалить продавца" color="warn" class="table-action-item"
                      (click)="removeSellerEmail(element.title)">

                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </form>
    <ng-container *ngIf="formChanged$ | async"></ng-container>
    <ng-container *ngIf="loadSellerList | async"></ng-container>
  `,
})

export class FormlyAdditionalEmails extends FieldType<FieldTypeConfig> implements OnInit {
  private toastrService = inject(ToastrService);

  public additionalEmailForm!: FormGroup;
  public dataSource: any = [];
  public displayedColumns: string[] = ['id', 'email', 'actions'];
  public defaultFormControlValue: Array<string> | [] = [];
  public formChanged$!: Observable<any>;
  public loadSellerList!: Observable<any>;

  ngOnInit(): void {
    if (this.formControl.value) {
      this.defaultFormControlValue = this.formControl.value;
      this.dataSource = this.prepareDate(this.defaultFormControlValue);
    }

    this.additionalEmailForm = new FormGroup({});
    this.additionalEmailForm.addControl(this.props[`formFieldName`], new FormControl('', [Validators.email]));

    this.formChanged$ = this.formControl.valueChanges.pipe(
      tap((value): void => {
        this.dataSource = this.prepareDate(value);
      })
    );
  }

  public addAdditionalEmail(): void {
    const formFieldName: string = this.props[`formFieldName`];
    const inputValue: string | null = this.additionalEmailForm.get(formFieldName)?.value;

    if (this.additionalEmailForm.get(formFieldName)?.value && this.additionalEmailForm.get(formFieldName)?.status === 'VALID') {
      let emailOfTable = this.dataSource.map((item: any) => item.title);
      const findMatch = emailOfTable.find((element: string): boolean => element === inputValue);

      if (findMatch) {
        this.toastrService.error(`Продавец с почтовым адресом ${inputValue} уже добавлен`);
        return;
      }

      if (!findMatch) {
        emailOfTable = [...emailOfTable, inputValue];
        this.formControl.setValue(emailOfTable);
      }
    }
  }

  public removeSellerEmail(email: string): void {
    let emailsOfTable = this.dataSource.map((item: any) => item.title).filter((item: string): boolean => item !== email);

    this.dataSource = this.prepareDate(emailsOfTable);
    this.formControl.setValue(emailsOfTable);
  }

  private prepareDate(data: any): Array<{ id: number, title: string }> {
    if (!!data && data.length) {
      return data.map((item: string, index: number): { id: number, title: string } => {
        return {
          id: index,
          title: item
        }
      })
    }

    return [];
  }
}
