import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from "@directives/directives.module";
import { PipesModule } from "@pipes/pipes.module";
import { MaterialModule } from "@shared/angular/material/material.module";
import { ComponentsModule } from "./components/components.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    DirectivesModule,
    ComponentsModule,
    PipesModule,
  ],
  exports: [
    MaterialModule,
    DirectivesModule,
    ComponentsModule,
    PipesModule,
  ],
})
export class SharedModule {}
