import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'PUBLISHED':
        return 'Активен';
      case 'COMPLETED':
        return 'Завершен';
      case 'NEW':
        return 'На модерации';
      case 'READY_TO_TRANSFER':
        return 'Готов к переводу';
      case 'UNPUBLISHED':
        return 'Возвращенный';
      case 'TRANSFERRED':
        return "Передан";
      case 'PAID':
        return "Оплачен";
      case'NOT_PAID':
        return "Не оплачен";
      case 'ARCHIVED':
        return 'Перенесен в архив';
      case 'REFUSED':
        return 'Отказ';
    }
    return 'Без статуса';
  }
}
