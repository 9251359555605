import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class HandleErrorService {
  constructor(private toaster: ToastrService) {}

  // Handling HTTP Errors using Toaster
  public handleError(err: any) {
    const errObj = { statusText: '', message: '' };
    if (err.error) {
      errObj.statusText = err?.status === 403 ? 'Нет доступа' : (err.statusText ?? 'Ошибка');
      errObj.message = err?.error?.message ?? err.message;
    } else {
      errObj.statusText = err?.status === 403 ? 'Нет доступа' : (err.statusText ?? 'Ошибка');
      errObj.message = err.message ?? 'Что-то пошло не так. Свяжитесь с администратором.';
    }

    this.toaster.error(errObj.message, errObj.statusText);
  }

  public handleSuccess(msg: any) {
    if (msg && msg.message) {
      this.toaster.success(msg.message, '');
    } else {
      this.toaster.success('Действие выполнено', '', { timeOut: 2000 });
    }
  }
}
