import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableTemplate } from "./table-template.directive";


@NgModule({
  declarations: [TableTemplate],
  imports: [
    CommonModule,
  ],
  exports: [
    TableTemplate,
  ],
})
export class DirectivesModule {}
