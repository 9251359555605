import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';


@Component({
  selector: 'formly-panel',
  template: `
    <h3>{{props.label}}</h3>
    <div class="formly-panel">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
  styles: [
    `
      .formly-panel {
        width: 100%;
        display: flex;
        gap: 10px;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid black;
      }
    `,
  ],
})
export class FormlyPanelComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) _fieldComponent!: ViewContainerRef;
}
