<mat-form-field style="width: 100%" (click)="preventEvent($event, picker)">
  <mat-label>Задайте промежуток</mat-label>
  <mat-date-range-input [max]="maxDate" [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="ДД.ММ.ГГ"
           (click)="preventEvent($event, picker)"
           (keydown)="preventEvent($event, picker)">
    <input matEndDate formControlName="end" placeholder="ДД.ММ.ГГ"
           (click)="preventEvent($event, picker)"
           (keydown)="preventEvent($event, picker)">
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker style="width: 100%;" #picker></mat-date-range-picker>
</mat-form-field>
