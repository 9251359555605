import {Component, Input} from '@angular/core';
import {BaseFormFieldAbstractComponent} from "@models/base-form-field.abstract.component";

@Component({
  selector: 'app-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss']
})
export class SelectWithSearchComponent extends BaseFormFieldAbstractComponent {
  @Input() label: string = '';
  @Input() formClass: string = '';
  @Input() optionLabel: string = 'title';
  @Input() options: any[] = [];

  public displayWith(item: any): any {
    return item ? item.title : undefined;
  }
}
