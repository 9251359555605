import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";
import {Router} from "@angular/router";

@Directive({
  selector: '[change-style]'
})
export class ChangeRowStyleDirective implements OnInit {
  @Input() row: any;

  constructor(private renderer: Renderer2, private el: ElementRef, private router: Router) {
  }

  ngOnInit(): void {
    this.changeColor();
  }

  private changeColor(): void {
    const route = this.router.url.split(/\?/);
    const separateRouter = route[0].split('/');

    if (separateRouter[1] === 'lots' && separateRouter[2] === 'transferred') {
      if (this.row?.status === 'NOT_PAID' && new Date() > new Date(this.row?.buyer_commission_pay_till)) {
        this.renderer.setStyle(this.el.nativeElement, 'background-color', '#FA8071');
      }

      if (this.row?.status === 'PAID') {
        this.renderer.setStyle(this.el.nativeElement, 'background-color', '#90EE90');
      }
    }

    if (separateRouter[1] === 'payments') {
      if (this.row?.status === 'REFUND') {
        this.renderer.setStyle(this.el.nativeElement, 'background-color', '#FA8071');
      }

      if (this.row?.status === 'PAID') {
        this.renderer.setStyle(this.el.nativeElement, 'background-color', '#90EE90');
      }
    }

  }
}
