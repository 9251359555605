import { Component, Input } from '@angular/core';

@Component({
  selector: 'mig-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() public showFilter?: boolean;
  @Input() public buttonType?: string;
  @Input() public icon!: string;
  @Input() public class!: string;
  @Input() public tooltip!: string;
  @Input() public disabled!: boolean;
}
