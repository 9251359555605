import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';
import { TableColumn } from "@shared/components/table/table.component";
import { Subject } from "rxjs";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { damagesToTableView } from "@shared/helpers";

const COLUMNS_DAMAGE: TableColumn[] = [
  { name: 'title', title: 'Зона повреждения' },
  { name: 'degree', title: 'Степень повреждения' },
  { name: 'description', title: 'Описание' },
  { name: 'custom', title: '', custom: true },
];

@Component({
  selector: 'formly-damage',
  template: `
    <form *ngIf="damageForm" class="formly-damage" [formGroup]="damageForm" (ngSubmit)="addDamage()">
      <mat-form-field appearance="outline"
                      color="primary"
                      class="formly-damage-item">
        <mat-label>Зона повреждения</mat-label>

        <mat-select formControlName="id">
          <mat-option *ngFor="let status of props['damageType'] | async" [value]="status.id">
            {{status.title}}
          </mat-option>
        </mat-select>

      </mat-form-field>
      <mat-form-field appearance="outline"
                      color="primary"
                      class="formly-damage-item">
        <mat-label>Степень повреждения</mat-label>

        <mat-select formControlName="degree">
          <mat-option *ngFor="let status of props['damageLevel']" [value]="status.id">
            {{status.title}}
          </mat-option>
        </mat-select>

      </mat-form-field>
      <mat-form-field appearance="outline" color="primary" class="formly-damage-item">
        <mat-label>Описание</mat-label>
        <textarea formControlName="description" matInput rows="1"></textarea>
      </mat-form-field>

      <button matTooltip="Добавить повреждение" mat-icon-button style="color:green;" class="table-action-item"
              type="submit">
        <mat-icon>add</mat-icon>
      </button>
    </form>

    <app-table
      matSort
      class="full-width bordered header-filled"
      [columns]="damageColumns"
      [dataSource]="damageDataSource$ | async">
      <ng-container matColumnDef="custom">
        <th mat-header-cell *matHeaderCellDef="let row">
        </th>
        <td mat-cell *matCellDef="let row; let i = index" class="custom-cell bordered">
          <div class="table-action">
            <button mat-icon-button color="warn" class="table-action-item" (click)="props['deleteDamage'](i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
    </app-table>
    <div class="formly-damage-empty-placeholder" *ngIf="showEmptyPlaceholder">Данные отсутствуют</div>
  `,
  styles: [`
             .formly-damage {
               display: flex;
               gap: 10px;
               justify-content: space-between;
               padding-bottom: 10px;

               &-item {
                 width: 30%;
               }

               &-empty-placeholder {
                 display: flex;
                 justify-content: center;
                 align-items: center;
                 width: 100%;
                 height: 50px;
                 font-size: 20px;
                 border: 1px solid black;
                 border-radius: 5px;
               }
             }
           `],
})
export class FormlyDamageControl extends FieldType<FieldTypeConfig> implements OnInit {
  public damageForm!: FormGroup;
  public damageDataSource$: Subject<any> = new Subject();
  public damageColumns = [...COLUMNS_DAMAGE];
  public showEmptyPlaceholder: boolean = false;

  constructor(private formBuilder: FormBuilder) {super();}

  ngOnInit() {
    this.showEmptyPlaceholder = !this.formControl.value;
    this.damageForm = this.formBuilder.group(
      {
        id: new FormControl('', [Validators.required]),
        degree: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
      },
    );
    this.field.formControl.valueChanges.pipe().subscribe(
      res => {
        const data = damagesToTableView(res);
        this.showEmptyPlaceholder = !data.length;
        this.damageDataSource$.next(data);
      },
    );
  }

  addDamage(): void {
    if (this.damageForm.valid) {
      this.props['addDamage'](this.damageForm.value);
    }
  }
}
