<mat-form-field appearance="outline" [class]="formClass + ' full-width'">
  <mat-label>{{label}}</mat-label>

  <input type="text" matInput [formControl]="formControl" [matAutocomplete]="auto">

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
    <mat-option *ngFor="let status of options" [value]="status">
      {{status[optionLabel]}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
