<div class="table">
  <table mat-table [dataSource]="dataSource" [class]="class" (contentChanged)="contentChanged.emit()">
    <caption>{{ caption }}</caption>

    <ng-content></ng-content>

    <ng-container [matColumnDef]="column.name" *ngFor="let column of defaultColumns">
      <th mat-header-cell *matHeaderCellDef style="color: #424242;">{{ column.title }}</th>
      <td mat-cell *matCellDef="let row" style="color: #868686;">{{ row[column.name] }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row change-style [row]="row" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>


<div class="empty-table" *ngIf="rowDefs.length"
     [ngStyle]="{'display':rowDefs.length ? 'none' : 'block' }">
  {{rowDefs.length > 0 ? '' : 'Данные отсутствуют'}}
</div>
