import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './not-found/not-found.component';
import {MaterialModule} from "@shared/angular/material/material.module";
import {RouterModule} from "@angular/router";
import {ButtonComponent} from "./button/button.component";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {PaginatorRus} from "@services/paginator-rus.service";

import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {AppTableComponent} from "@shared/components/table/table.component";
import {AppTabComponent} from "@shared/components/tabs/tabs.component";
import {DatePickerComponent} from "@shared/components/forms/date-picker/date-picker.component";
import {RangeDatePickerComponent} from "@shared/components/forms/range-date-picker/range-date-picker.component";
import {SelectComponent} from "@shared/components/forms/select/select.component";
import {InputComponent} from "@shared/components/forms/input/input.component";
import {MigDateAdapter, APP_DATE_FORMATS} from "@shared/components/forms/range-date-picker/date.adapter";
import {FormlyPanelComponent} from "@shared/components/forms/formly/formly-prime-panel";
import {FormlyFileUploader, FileValueAccessor} from "@shared/components/forms/formly/formly-file-uploader";
import {FormlyModule} from "@ngx-formly/core";
import {FormlyPictureUploader} from "@shared/components/forms/formly/formly-picture-uploader";
import {FormlyMultiplePictureUploader} from "@shared/components/forms/formly/formly-multiple-picture-uploader";
import {FormlyAutocomplete} from "@shared/components/forms/formly/formly-autocomplete";
import {DialogComponent} from "@shared/components/dialog/dialog.component";
import {FormlyDamageControl} from "@shared/components/forms/formly/formly-damage-control";
import {FormlyTime} from "@shared/components/forms/formly/formly-time";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {
  ChangeRowStyleDirective
} from "@shared/components/table/shared/change-row-style.directive";
import {FormlyRelatedSeller} from "@shared/components/forms/formly/formly-related-seller";
import {FormlyAdditionalEmails} from "@shared/components/forms/formly/formly-additional-emails";
import {ButtonActionsComponent} from './button-actions/button-actions.component';
import {SelectWithSearchComponent} from './forms/select-with-search/select-with-search.component';

const COMPONENTS = [
  ButtonComponent,
  NotFoundComponent,
  AppTableComponent,
  AppTabComponent,
  DatePickerComponent,
  RangeDatePickerComponent,
  SelectComponent,
  InputComponent,
  DialogComponent,
  FormlyPanelComponent,
  FormlyFileUploader,
  FormlyRelatedSeller,
  FormlyAdditionalEmails,
  FileValueAccessor,
  FormlyPictureUploader,
  FormlyMultiplePictureUploader,
  FormlyAutocomplete,
  FormlyDamageControl,
  FormlyTime,
  ButtonActionsComponent,
  ChangeRowStyleDirective,
  SelectWithSearchComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyModule,
    NgxMaterialTimepickerModule,
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    {provide: DateAdapter, useClass: MigDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: MatPaginatorIntl, useClass: PaginatorRus},
  ],
})
export class ComponentsModule {
}
