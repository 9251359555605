import {Component} from "@angular/core";
import {FieldType} from "@ngx-formly/material";
import {FieldTypeConfig} from "@ngx-formly/core";

@Component({
  selector: 'mig-time-picker',
  template: `
    <mat-form-field color="primary" style='width: 100%' appearance="outline">
      <mat-label>{{props.label}}</mat-label>
      <input type="time"
             min="00:00"
             max="23:59"
             matInput
             [placeholder]="'Введите значение'"
             [required]="required"
             [formControl]="formControl">

      <mat-icon matPrefix
                *ngIf="formControl.value"
                (click)="onClear()">
        close
      </mat-icon>
    </mat-form-field>
  `,
})

export class FormlyTime extends FieldType<FieldTypeConfig> {

  onClear() {
    this.formControl.setValue(null);
  }
}
