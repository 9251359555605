<div class="dialog-window">
  <!--  <div class="dialog-center">-->
  <!--    <mat-icon>warning</mat-icon>-->
  <!--  </div>-->
  <div mat-dialog-content class="dialog-message">{{ customDialog.message }}</div>
  <div class="dialog-buttons">
    <button mat-raised-button type="button" (click)="cancelClick()">{{ customDialog.cancelCallbackButtonText }}</button>
    <button mat-raised-button type="button" (click)="submitClick()">{{ customDialog.submitCallbackButtonText }}</button>
  </div>
</div>
