import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from '@auth/login/login.component';
import {LogoutComponent} from '@auth/logout/logout.component';
import {AngularSvgIconModule} from "angular-svg-icon";
import {AngularSvgIconPreloaderModule} from "angular-svg-icon-preloader";
import {SharedModule} from "@shared/shared.module";
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormlyModule} from "@ngx-formly/core";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

import {FormlyMatDatepickerModule} from "@ngx-formly/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {FormlyMatToggleModule} from "@ngx-formly/material/toggle";
import {ToastrModule} from "ngx-toastr";
import {AuthInterceptor} from "@interceptors/auth.interceptor";
import {FormlyPanelComponent} from "@shared/components/forms/formly/formly-prime-panel";
import {FormlyFileUploader} from "@shared/components/forms/formly/formly-file-uploader";
import {FormlyPictureUploader} from "@shared/components/forms/formly/formly-picture-uploader";
import {FormlyMultiplePictureUploader} from "@shared/components/forms/formly/formly-multiple-picture-uploader";
import {FormlyAutocomplete} from "@shared/components/forms/formly/formly-autocomplete";
import {HttpErrorInterceptor} from "@interceptors/http-error.interceptor";
import {FormlyDamageControl} from "@shared/components/forms/formly/formly-damage-control";
import {FormlyTime} from "@shared/components/forms/formly/formly-time";
import {FormlyRelatedSeller} from "@shared/components/forms/formly/formly-related-seller";
import {FormlyAdditionalEmails} from "@shared/components/forms/formly/formly-additional-emails";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: './assets/svg/setup.json',
    }),
    FormlyModule.forRoot({
      types: [
        {name: 'file', component: FormlyFileUploader},
        {name: 'main_image', component: FormlyPictureUploader},
        {name: 'images', component: FormlyMultiplePictureUploader},
        {name: 'damage', component: FormlyDamageControl},
        {name: 'time-picker', component: FormlyTime},
        {name: 'related-seller', component: FormlyRelatedSeller},
        {name: 'additional-email', component: FormlyAdditionalEmails},
        {name: 'autocomplete', component: FormlyAutocomplete, wrappers: ['form-field']},
      ],
      validationMessages: [{name: 'required', message: 'Обязательное поле'}],
      wrappers: [
        {name: 'formly-panel', component: FormlyPanelComponent},
      ],
    }),
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    ToastrModule.forRoot(
      {
        timeOut: 2000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      },
    ),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', color: 'warn'}},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
