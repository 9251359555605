import {Validators, FormGroup, UntypedFormControl} from "@angular/forms";
import {Component, inject, OnInit} from '@angular/core';
import {takeUntil} from "rxjs";

import {AuthService} from "@services/auth.service";
import {AppBaseDestroyComponent} from "@shared/components/base-component/base-component.component";

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent extends AppBaseDestroyComponent implements OnInit{
  private _auth = inject(AuthService);

  public form!: FormGroup;
  public hide = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  public submitForm() {
    this.form.getRawValue();
    this._auth.login({...this.form.getRawValue()}).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  public get email() {
    return this.form.get('username');
  }

  public get password() {
    return this.form.get('password');
  }

  private initializeForm(): void {
    this.form = new FormGroup({
      username: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required, Validators.min(3)]),
    });
  }
}
