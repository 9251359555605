import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-multi-picture-uploader',
  template: `
    <span style="margin-right: 10px">{{props.label}}</span>
    <input [formlyAttributes]="field" [hidden]="true" type="file" [formControl]="formControl">
    <input [hidden]="true" type="file" (change)="props['fileUpload'](pdfInput.files)"
           accept="image/png, image/jpeg" multiple #pdfInput/>
    <div class="images-container">
      <div class="images-actions-wrapper" *ngFor="let img of formControl.value; let i = index">
        <div class="images-actions">
          <button mat-icon-button class="images-actions-item" (click)="props['watch'](img.original_url)">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-icon-button class="images-actions-item" (click)="props['download'](i)">
            <mat-icon>cloud_download</mat-icon>
          </button>
          <button mat-icon-button class="images-actions-item" (click)="props['deleteImage'](img)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button class="images-actions-item" (click)="props['changeMainPhoto'](img)">
            <mat-icon>star</mat-icon>
          </button>
        </div>
        <img class="images-item" [src]="defineSrc(i)" alt="Фото">
      </div>
      <img class="images-item" (click)="pdfInput.click()" [src]="defaultImage" alt="Загрузить фото">
    </div>

  `,
  styles: [
    `
      .images {
        &-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        &-item {
          width: 150px;
          height: 150px;
          border: 1px solid gray;
          border-radius: 5px;
          cursor: pointer;
          object-fit: cover;

          &:hover {
            opacity: 0.7;
            border: 1px solid red;
          }
        }

        &-actions {
          position: absolute;
          left: 0;
          top: 0;
          display: none;

          &-item {
            z-index: 11;
            color: white;
            border: 1px solid white;
            border-radius: 50%;
            margin: 0 auto
          }

          &-wrapper {
            z-index: 10;
            border-radius: 5px;
            position: relative;
            width: 150px;
            height: 150px;

            &:hover {
              background-color: gray;
              opacity: 1;
              align-items: center;

              .images-item {
                opacity: 0.3;
              }

              .images-actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    `,
  ],
})
export class FormlyMultiplePictureUploader extends FieldType<FieldTypeConfig> {
  public defaultImage: string = 'assets/img/add_img.png';

  defineSrc(id: number): string {
    if (this.formControl.value) {
      return this.formControl.value[id].original_url;
    }
    return this.defaultImage;
  }
}

