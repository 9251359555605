<section class="form-container">
  <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
    <mat-form-field>
      <mat-label>Логин</mat-label>

      <input type="email" matInput formControlName="username" placeholder="Введите e-mail">

      <mat-error *ngIf="email?.hasError('username') && !email?.hasError('required')">
        Введите кооректный e-mail
      </mat-error>

      <mat-error *ngIf="email?.hasError('required')">
        Поле <strong>обязательно</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Пароль</mat-label>

      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>

      <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>

      <mat-error *ngIf="!(password?.value)">Введите пароль</mat-error>
    </mat-form-field>

    <button [disabled]="form.invalid" mat-raised-button color="primary">
      Войти
    </button>
  </form>
</section>
