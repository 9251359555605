import * as moment from "moment";
import { PageFilter } from "@models/page-filter.model";
import { DAMAGE_LEVEL } from "@shared/constants";

export function dateToMomentFormat(date: Date, format?: string) {
  const timeZone: number = new Date().getTimezoneOffset();
  const formatConst: string = 'YYYY-MM-DD HH:mm:ss';
  return moment(date).utcOffset(-1 * (timeZone * 2)).format(format || formatConst);
}

export function damagesToTableView(damages: Record<string, string>[]): Record<string, string>[] {
  if (!damages || !damages.length) {
    return [];
  }
  return damages.map((item: any) => {
    return {
      id: item.id,
      description: item.description,
      degree: DAMAGE_LEVEL.find((level: {
        id: string,
        title: string
      }) => level.id == item.degree)?.title || '',
      title: item.title,
    };
  });
}

export function paramsToString(filters: PageFilter, availableFilters: string[]): string {
  const defaultSort = ["order", "sort", "limit", 'page'];
  const stringFilters: string[] = [''];

  const fl = Object.entries(filters).map(([k, v]) => ({
    filter: k,
    value: v,
  }));

  fl.forEach(f => {
    if (f.value && !defaultSort.includes(f.filter) && availableFilters.includes(f.filter)) {
      stringFilters.push(`${f.filter}=${f.value}`);
    }
  });


  return stringFilters.join('&');
}


export function onlyUnique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}
