import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable, map} from 'rxjs';
import {tap} from "rxjs/operators";

import {environment} from "@env/environment";
import {API_HANDLERS} from "../api-record";


const ADMIN_TOKEN = 'adminToken';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  private _http = inject(HttpClient);
  private _router = inject(Router);

  public redirectUrl: string[] | string | undefined;
  private _token!: Record<string, string> | null;

  constructor() {
  }

  login(formData: { username: string, password: string }): Observable<any> {
    const {username, password} = formData;
    const form: FormData = new FormData();
    form.append('email', username);
    form.append('password', password);

    let body = {
      email: form.get('email'),
      password: form.get('password'),
      remember: true,
    };

    return this._http.post(environment.apiUrl + API_HANDLERS['login'], body).pipe(
      tap((item: any) => {
        localStorage.setItem(ADMIN_TOKEN, JSON.stringify(item));
        this._token = item;
        this._router.navigate(['lots', 'all']);
      })
    );
  }

  logout(): void {
    this._token = null;
    localStorage.removeItem(ADMIN_TOKEN);
  }

  get token(): Record<string, string> {
    const tokenFromStorage = JSON.parse(localStorage.getItem(ADMIN_TOKEN) as string);
    return this._token || tokenFromStorage;
  }

  //
  // logout(): Observable<void> {
  //   return this._http.post(`${environment.apiUrl}/api/auth/logout`, {}, {});
  // }
}
