import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup, FormControl} from "@angular/forms";
import {MatDateRangePicker} from "@angular/material/datepicker";

@Component({
  selector: 'mig-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss'],
})
export class RangeDatePickerComponent implements OnInit, OnChanges {
  @Input() public class!: string;
  @Input() public isClearDateRange: boolean = false;
  @Output() public dateChanged = new EventEmitter<{ start_date: string, end_date: string }>();

  public maxDate: Date = new Date();
  public range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });


  ngOnInit(): void {
    this.range.valueChanges.subscribe(res => {
      const {start, end} = res;
      const date = {start_date: this.convertFormat(start), end_date: this.convertFormat(end)};
      this.isClearDateRange = false;
      this.dateChanged.emit(date);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['isClearDateRange'].currentValue ? this.range.reset() : null;
  }

  public preventEvent(event: Event, picker: MatDateRangePicker<any>) {
    event.preventDefault();
    picker.open();
  }

  private convertFormat(date: Date | null | undefined): string {
    if (date == null) {
      return '';
    }

    return date.toLocaleDateString().replaceAll('.', '-');
  }
}
