import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatFormFieldAppearance } from '@angular/material/form-field';

export enum FormFieldFloatLabelMode {
  auto   = 'auto',
  always = 'always',
  never  = 'never'
}

@Component({
  template: '',
})
export abstract class BaseFormFieldAbstractComponent implements OnDestroy {
  protected destroyed$: Subject<void> = new Subject<void>();
  @Input() icon: string = '';
  @Input() customRequiredKey: string = '';
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() value: any;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() control: AbstractControl = new FormControl();
  @Input() floatLabel: FormFieldFloatLabelMode = FormFieldFloatLabelMode.never;
  @Input() maxLength: number = 256;
  @Input() autocomplete: string = 'off';

  constructor() {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get formControl(): FormControl {
    return this.control as FormControl;
  }
}
