import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { HandleErrorService } from "@services/http-error.service";
import { Router } from "@angular/router";


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private errorService: HandleErrorService,
    private _router: Router,
  ) {
  }

  // intercept function
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        this.errorService.handleError(error);
        if (error.status == 401) {
          window.location.href = 'login';
        }
        return throwError(errorMsg);
      }),
    );

  }
}

