import { Component, OnInit } from '@angular/core';
import { AuthService } from "@services/auth.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private _auth: AuthService) {}

  ngOnInit(): void {
    this._auth.logout();
    window.location.href = '/login';
  }
}
