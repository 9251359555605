import { Component, Input } from '@angular/core';
import { BaseFormFieldAbstractComponent } from "@models/base-form-field.abstract.component";

@Component({
  selector: 'mig-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent extends BaseFormFieldAbstractComponent {
  @Input() label: string = '';
  @Input() maxDate!: Date;
  @Input() minDate!: Date;
}
