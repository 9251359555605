import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button-actions',
  templateUrl: './button-actions.component.html',
  styleUrls: ['./button-actions.component.scss']
})
export class ButtonActionsComponent {
  @Output() public addNewHandbookItem: EventEmitter<any> = new EventEmitter();
  @Output() public deleteHandbookItem: EventEmitter<any> = new EventEmitter();
  @Input() public disabled: boolean | undefined

  public add(): void {
    this.addNewHandbookItem.emit();
  }

  public delete(): void {
    this.deleteHandbookItem.emit();
  }
}
