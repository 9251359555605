import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {TableChunkParams} from 'src/app/shared/models/table-chunk-params.model';
import {ITableDataManager} from "@interfaces/itable-data-manager.interface";
import {ChunkedData} from "@models/page-data.model";
import {PageFilter} from "@models/page-filter.model";
import {environment} from "@env/environment";
import {TARIFFS_LIST, USERS_API_HANDLERS} from "@shared/api/api-record";
import {Params} from "@angular/router";


@Injectable()
export class UsersManager implements ITableDataManager<any> {
  public total: Observable<number>;
  public filters: Observable<PageFilter>;
  public filterStream: BehaviorSubject<PageFilter> = new BehaviorSubject<PageFilter>(new PageFilter());
  private totalStream: Subject<number> = new Subject<number>();

  constructor(private http: HttpClient) {
    this.filters = this.filterStream;
    this.total = this.totalStream;
  }

  public load(chunkSettings?: TableChunkParams): Observable<any[]> {
    let params = new HttpParams();
    const filterStreamValue: any = this.filterStream.value;

    if (!filterStreamValue.role) {
      params = params.append('role[]', 'buyer');
      params = params.append('role[]', 'seller');
    } else if (filterStreamValue.role === 'admin') {
      params = params.append('role[]', 'admin');
      params = params.append('role[]', 'super_admin');
    } else {
      params = params.append('role[]', filterStreamValue.role);
    }

    params = params.append('active', filterStreamValue.active);

    if (filterStreamValue.search) {
      params = params.append('search', filterStreamValue.search);
    }

    return this.http.get<ChunkedData<any>>(
      environment.apiUrl + USERS_API_HANDLERS['userProfilesList'] + '?' + chunkSettings?.toQueryString(), {
        params
      }
    ).pipe(
      tap(result => this.totalStream.next(result.meta['total'])),
      map(result => result.data),
    );
  };

  /**
   * Применить новые фильтры для поиска.
   * @param filters Новые фильтры
   */
  public applyFilters(filters: PageFilter): void {
    this.filterStream.next(filters);
  }

  public updateUser(id: number | string, user: any) {
    return this.http.put<any>(environment.apiUrl + USERS_API_HANDLERS['updateUser'] + id, user);
  }

  public createUser(userData: any) {
    return this.http.post<any>(environment.apiUrl + USERS_API_HANDLERS['createUser'], userData);
  }

  public loadSingleUser(id: number | string): Observable<any> {
    return this.http.get<Record<string, any>>(environment.apiUrl + USERS_API_HANDLERS['loadSingleUser'] + id).pipe(
      map(item => item['data']),
    );
  }
  public loadOnlySeller(params: Params): Observable<any[]> {
    return this.http.get<ChunkedData<any>>(
      environment.apiUrl + USERS_API_HANDLERS['userProfilesList'], {
        params
      }
    ).pipe(map(result => result.data));
  };


  public loginAsUser(userId: string): Observable<any> {
    return this.http.post(environment.apiUrl + USERS_API_HANDLERS['loginAsUser'] + userId, {})
  }

  public loadUserForPeriod(period: string): Observable<any> {
    let params = new HttpParams();

    params = params.append('period', period);
    return this.http.get(environment.apiUrl + USERS_API_HANDLERS['loadUsersForPeriod'], {responseType: 'blob', params});
  }

  public loadUsersTariffs(): Observable<any> {
    return this.http.get(environment.apiUrl + TARIFFS_LIST['tariffsList'])
  }
}
