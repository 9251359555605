import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "@auth/login/login.component";
import { LogoutComponent } from "@auth/logout/logout.component";
import { AuthenticationGuard } from "@guards/authentication.guard";

const routes: Routes = [
  { path: '', redirectTo: '/lots/all', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: (): any => import('./layouts/layouts.module').then(m => m.LayoutsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
