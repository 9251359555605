import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from "@angular/material/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { AngularSvgIconModule } from "angular-svg-icon";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from '@angular/material/dialog';
import { FormlyMaterialModule } from "@ngx-formly/material";
import { MatAutocompleteModule } from "@angular/material/autocomplete";


const MATERIAL = [
  MatNativeDateModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatSelectModule,
  AngularSvgIconModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatTableModule,
  MatTabsModule,
  MatExpansionModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatSortModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatDialogModule,
  FormlyMaterialModule,
  MatAutocompleteModule,
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...MATERIAL,
  ],
  exports: [
    ...MATERIAL,
  ],
})
export class MaterialModule {}
