import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[tableTemplate]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TableTemplate {

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @Input() tableTemplate: string;

  constructor(public template: TemplateRef<any>) {
  }

  getType(): string {
    return this.tableTemplate;
  }
}
