import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { startWith, switchMap, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'formly-autocomplete-type',
  template: `
    <input
      matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder || 'Начните вводить значение'"
      [errorStateMatcher]="errorStateMatcher"
    >
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
      <mat-option *ngFor="let value of filter | async" [value]="value">
        {{ value.title }}
      </mat-option>
    </mat-autocomplete>
  `,
})
export class FormlyAutocomplete extends FieldType<FieldTypeConfig> implements OnInit {
  filter!: Observable<any>;

  ngOnInit() {
    this.filter = this.formControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      switchMap((term) => this.props['filter'](term)),
    );
  }

  display(item: any): string {
    return item && item.title ? item.title : '';
  }
}
